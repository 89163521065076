// @ts-nocheck
import { MutationTypesTable } from "./mutation-style";
export default {


    [MutationTypesTable.ADD_SELECTED_ROW]: async ({ commit }, payload) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        try {

            commit(MutationTypesTable.ADD_SELECTED_ROW, payload);
        } catch (error) {
            console.log(error);
        }
    },
    [MutationTypesTable.REMOVE_SELECTED_ROW]: async ({ commit }, payload) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        try {

            commit(MutationTypesTable.REMOVE_SELECTED_ROW, payload);
        } catch (error) {
            console.log(error);
        }
    },
    [MutationTypesTable.RESET_SELECTED_ROW]: async ({ commit }, payload) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        try {

            commit(MutationTypesTable.RESET_SELECTED_ROW, payload);
        } catch (error) {
            console.log(error);
        }
    },
    [MutationTypesTable.SELECT_All_ROW]: async ({ commit }, payload) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        try {

            commit(MutationTypesTable.SELECT_All_ROW, payload);
        } catch (error) {
            console.log(error);
        }
    },
    [MutationTypesTable.SET_TABLE_DATA]: async ({ commit }, payload) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        try {

            commit(MutationTypesTable.SET_TABLE_DATA, payload);
        } catch (error) {
            console.log(error);
        }
    },
    [MutationTypesTable.QUERY_TABLE_DATA]: async ({ commit }, payload) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        try {

            commit(MutationTypesTable.QUERY_TABLE_DATA, payload);
        } catch (error) {
            console.log(error);
        }
    },
    [MutationTypesTable.SET_PAYLOAD_EDIT]: async ({ commit }, payload) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        try {

            commit(MutationTypesTable.SET_PAYLOAD_EDIT, payload);
        } catch (error) {
            console.log(error);
        }
    },

    [MutationTypesTable.SET_ALLOW_EDIT_LIST]: async ({ commit }, payload) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        try {

            commit(MutationTypesTable.SET_ALLOW_EDIT_LIST, payload);
        } catch (error) {
            console.log(error);
        }
    },
}