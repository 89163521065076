<script lang="ts" src="./dashboard.ts"></script>
<style scoped lang="scss" src="./dashboard.scss"></style>

<template>
  <div class="d-flex flex-column" style="gap: 16px">
    <div class="overView d-flex justify-content-between">
      <div class="overView-items" v-for="(item, index) in overViews" :key="index" @click="handleDetailsOverView">
        <img
          :src="require(`@/assets/images/logos/png/${item?.image}.png`)"
          style="width: 80px; height: 80px; object-fit: contain"
        />
        <div class="d-flex flex-column justify-content-between" style="gap: 4px">
          <p class="text-title">{{ item?.title }}</p>
          <p class="text-description">{{ item?.quantity }}</p>
        </div>
      </div>
    </div>
    
  </div>
</template>
