<script lang="ts" setup>
import { computed, defineProps } from "vue";
import {
  COGNITIVE_CHART_DATA,
  COGNITIVE_CHART_OPTIONS,
  COGNITIVE_DEFAULT_API_DATA,
  COGNITIVE_KEYS,
} from "../../constants";
import { CognitiveDataFromAPI } from "../../type";
import RadarChart from "../common/RadarChart.vue";

interface Props {
  data: CognitiveDataFromAPI;
  updateDate?: string;
}

const props = withDefaults(defineProps<Props>(), {
  data: () => COGNITIVE_DEFAULT_API_DATA,
  updateDate: "",
});

const chartData = computed(() => {
  let visualizationData = { ...COGNITIVE_CHART_DATA };
  let chartValues: string[] = [];
  if (props.data) {
    for (let i = 0; i < COGNITIVE_KEYS.length; i++) {
      const key = COGNITIVE_KEYS[i];
      const value = props.data[key];
      if (value) {
        chartValues.push(value.toFixed(2));
      } else {
        chartValues.push("0");
      }
    }
    // Need to do this because of comparisons deeep object for the chart
    visualizationData.datasets = [
      {
        fill: true,
        borderColor: "#13A7A4",
        backgroundColor: "#E9F5E94D",
        pointBackgroundColor: "#13A7A4",
        tension: 0.1,
        data: chartValues as never[],
      },
    ];

    return visualizationData;
  }
  return COGNITIVE_CHART_DATA;
});
</script>

<template>
  <div class="cogChart">
    <div class="cogChart-header">
      <p class="cogChart-title">Cognitive Assessment Review</p>
      <p class="cogChart-date">Updated: {{ updateDate }}</p>
      <p class="cogChart-subtitle">
        This statistic is only applicable to data from Chat Tutor and Tutor
        session.
      </p>
    </div>
    <div class="cogChart-radar">
      <RadarChart :data="chartData" :options="COGNITIVE_CHART_OPTIONS" />
    </div>
    <div class="cogChart-note">
      The child's cognitive assessment indicates areas of potential growth, with
      average memory skills, room for improvement in inhibition, average
      processing speed, promising cognitive flexibility, and opportunities to
      enhance attention span.
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../styles/index.scss";

.cogChart {
  @include flexbox(column, center, center, null, 1 0 0%, 12px);
  width: 50%;
  overflow: hidden;
  &-header {
    width: 100%;
    @include flexbox(column, flex-start, flex-start, null, null, 8px);
  }
  &-title {
    @include title;
  }

  &-date {
    @include typography(12px, 400, 1.5, normal, $primary-500);
  }

  &-subtitle {
    @include subtitle;
    text-align: left;
  }

  &-radar {
    width: 60%;
  }

  &-note {
    text-align: left;
    border-radius: 24px;
    padding: 16px;
    background-color: $secondary-500;
    @include subtitle;
    color: $neutral-200;
  }
  @include responsive-for(tablet) {
    @include flexbox(column, flex-start, center, null, null, 12px);
    overflow: unset;
    width: 100%;
    &-radar {
      width: 80%;
      display: flex;
      justify-content: center;
    }
  }
}
</style>
