<script lang="ts" src="./create-ai-character.ts"></script>
<style scoped lang="scss" src="./create-ai-character.scss"></style>
<!-- <style src="@vueform/toggle/themes/default.css"></style> -->

<template v-if="AIInfo.type === 'chat'">
  <ModalBase
    ref="modal-base-delete"
    title="Delete this blog"
    btnAction="Yes"
    btnCancel="Cancel"
    description="Are you sure you want to delete this blog?"
    :isDanger="true"
    @handleClickBtnAction="handleDelete"
  />

  <ModalBase
    ref="modal-base-confirm-goback"
    title="Save"
    btnAction="Yes"
    btnCancel="No"
    description="Do you want to save this character?"
    @handleClickBtnAction="handleSaveDraft"
    @handleClickCancel="handleGoBackWithoutSave"
  />

  <ModalBase
    ref="modal-base-delete-scenario"
    title="Delete this scenario"
    btnAction="Yes"
    btnCancel="Cancel"
    description="Are you sure you want to delete this scenario?"
    :isDanger="true"
    @handleClickBtnAction="apiRemoveScenario"
  />
  <ModalCategory
    ref="modal-category"
    :categoryDataProps="selectOptions.categories"
  />
  <ModalUpdateTopicForScenario
    ref="modal-update-scenario-unknown-topic"
    :scenarioEditProps="scenarioUnknownTopicSelected"
    :topicsDataProps="topicsData"
    :scenariosUnknownTopicProps="scenarioUnknownTopic"
    @on-close-modal="scenarioUnknownTopicSelected = null"
  />
  <!-- @updateScenarioTopicData="onUpdateScenarioOfTopicSelected" -->

  <ShowTranslateLanguage
    ref="translate-language-modal-container"
    :translateArray="translateModalArray"
  />
  <div class="d-flex flex-column" style="gap: 24px">
    <!-- Header -->
    <div class="d-flex justify-content-between sticky-top-10">
      <div class="d-flex justify-content-center gap-3">
        <div
          class="zoomout-icon"
          style="
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 32px;
                        height: 32px;
                        padding: 6px;
                        border-radius: 50%;
                        background: var(--neutral-09-day, #fff);
                        box-shadow: 4px 8px 32px 0px rgba(0, 0, 0, 0.08);
                        backdrop-filter: blur(16px);
                    "
        >
          <img
            @click="handleGoBack"
            src="@/assets/images/icons/svg/arrow-left-black.svg"
            alt="arrow-left"
          />
        </div>
        <div>
          <p
            style="
                            color: var(--neutral-02-day, #222);
                            font-size: 22px;
                            font-weight: 600;
                        "
          >
            <template v-if="personAiEditId">
              Edit character:
              <i>{{ AIInfo.name }}</i>
            </template>
            <template v-else> Create character </template>
          </p>
        </div>
      </div>

      <div class="d-flex justify-content-center gap-3">
        <div>
          <button
            :class="
                            loading.saveDraft || loading.public
                                ? 'disabled-item'
                                : ''
                        "
            @click="handleOpenDeleteConfirm"
            class="button-outline"
            style="
                            color: #ff513c;
                            border: 1px solid var(--neutral-06-day, #d6d6d6);
                        "
          >
            Delete
          </button>
        </div>

        <!-- Dropdown button -->
        <div class="d-flex justify-content-center gap-3" style="flex: 1 1 25%" >
          <DropdownSelect
            :defaultSelected="selectedDefault"
            :options="OptionItems"
            @selectOption="selectedItem"
          />
        </div>

        <button
          @click="handleUpdate(true)"
          class="button-solid border-radius-12"
        >
            <span
              role="status"
              aria-hidden="true"
            />
          Update
        </button>


        <!--        <div>-->
        <!--          <button-->
        <!--            @click="handleSaveDraft(true)"-->
        <!--            class="button-outline"-->
        <!--            :class="-->
        <!--                            loading.saveDraft || loading.public-->
        <!--                                ? 'disabled-item'-->
        <!--                                : ''-->
        <!--                        "-->
        <!--          >-->
        <!--                        <span-->
        <!--                          v-if="loading.saveDraft"-->
        <!--                          class="me-2 spinner-border spinner-border-sm"-->
        <!--                          role="status"-->
        <!--                          aria-hidden="true"-->
        <!--                        />-->
        <!--            Save Draft-->
        <!--          </button>-->
        <!--        </div>-->
        <div>

          <!--          <button-->
          <!--            @click="handlePublic"-->
          <!--            :class="-->
          <!--            (loading.saveDraft || loading.public || !isFormValid)-->
          <!--                                ? 'disabled-item'-->
          <!--                                : ''-->
          <!--                        "-->
          <!--            class="button-solid border-radius-12"-->
          <!--            :disabled="loading.saveDraft || loading.public || !isFormValid"-->
          <!--          >-->
          <!--            <span-->
          <!--              v-if="loading.public"-->
          <!--              class="me-2 spinner-border spinner-border-sm"-->
          <!--              role="status"-->
          <!--              aria-hidden="true"-->
          <!--            />-->
          <!--            Publish-->
          <!--          </button>-->
        </div>
      </div>
    </div>

    <div v-if="AIInfo.id" class="rp-header sticky-top-10">
        <span class="h-calendar-icon">
           <CalendarIcon />
        </span>
      <span class="h-info">
          Last Updated:
          <b>
            {{
              new Date(AIInfo.update_date || "").toDateString()
            }}
          </b>
          by
          <b> {{ AIInfo.update_author }}</b>
        </span>
    </div>


    <!-- Profile pictures -->
    <div class="edit-container d-grid gap-3 picture-control-box">
      <div class="title-profile text-start">
        <p
          style="
                        text-transform: uppercase;
                        font-size: 16px;
                        font-weight: 600;
                    "
        >
          profile pictures
        </p>
      </div>
      <p class="warning-text" style="color: #ff8d06; text-align: left">
        Recommend: Avatar image (512px -512px), Detail image (512px
        -770px) JPG
      </p>
      <div class="d-flex align-items-start" style="gap: 20px">
        <template v-for="(items, key) in fieldsImageProfile as {}">
          <div class="d-grid gap-3">
            <div v-if="false" class="header-image">
              <p class="text">
                {{
                  //@ts-ignore
                  key.toString() === "details_image"
                    ? "Detail image"
                    : "Avatar Image"
                }}
              </p>
              <!-- <p class="size">512px -770px JPG</p> -->
            </div>

            <!-- Images Control -->
            <div class="d-flex justify-content-center gap-5">
              <template
                v-for="i in items as any"
                :key="i.name_handle_upload"
              >
                <div class="image-control">
                  <div
                    v-if="AIInfo[i.url_field_name]"
                    class="image-box-exist"
                    :class="
                                            key === 'details_image'
                                                ? 'details_image'
                                                : ''
                                        "
                  >
                    <img
                      class="img-fluid"
                      :src="AIInfo[i.url_field_name]"
                      :alt="i.url_field_name"
                    />
                    <div
                      @click="
                                                handleRemoveImage(
                                                    i.name_handle_upload
                                                )
                                            "
                      class="image-handle zoomout-icon"
                    >
                      <img
                        src="@/assets/images/icons/svg/delete-white.svg"
                        alt="delete-icon"
                      />
                    </div>
                  </div>
                  <label v-else class="no-image-box">
                    <div
                      class="control-box"
                      :class="
                                                key === 'details_image'
                                                    ? 'details_image'
                                                    : ''
                                            "
                    >
                      <input
                        ref="fileInputRef"
                        type="file"
                        accept=".png, .jpg, .jpeg, .gif"
                        @change="
                                                    handleAvatar(
                                                        $event,
                                                        i.name_handle_upload
                                                    )
                                                "
                      />
                      <div>
                        <img
                          class="align-middle"
                          width="24"
                          src="@/assets/images/icons/svg/edit-image-gray.svg"
                          alt="edit-image-gray"
                        />
                        <p
                          class="align-middle text-upload"
                        >
                          Upload image
                        </p>
                      </div>
                      <!-- </label> -->
                    </div>
                  </label>
                  <p class="text-school">
                    {{ i.title_school }}
                  </p>
                  <p
                    v-if="invalidMessage[i.url_field_name]"
                    class="text-error"
                  >
                    *{{ invalidMessage[i.url_field_name] }}
                  </p>
                </div>
              </template>
            </div>
          </div>
        </template>
      </div>
    </div>

    <!-- Information -->
    <AccordingPanel
      idTarget="ai-character-information"
      className=" small-button"
    >
      <template #title>
        <div class="according-header">
          <span class="according-header__title"> Information </span>
        </div>
      </template>
      <template #content>
        <div class="d-flex justify-content-between flex-wrap gap-3">
          <div class="text-start flex-auto" style="flex: 1 1 45%">
            <label style="font-size: 14px; font-weight: 500"
            >Character for feature</label
            >
            <DropdownSelect
              placeholder="Choose a feature"
              :defaultSelected="AIInfo.type"
              :options="selectOptions.characterTypes"
              @selectOption="selectOptionType"
            />
            <p v-if="invalidMessage.type" class="text-error">
              *{{ invalidMessage.type }}
            </p>
          </div>
          <div class="text-start flex-auto" style="flex: 1 1 45%">
            <div
              style=" display: flex;
              justify-content: space-between;
              margin-bottom: 5px;"
            >
              <label style="font-size: 14px; font-weight: 500">
                Category
              </label>
              <span
                class="text-green"
                style="cursor: pointer"
                @click="handleOpenModalCategory()"
              >
                View all
              </span>
            </div>
            <DropdownSelect
              placeholder="Choose a category"
              :defaultSelected="AIInfo.category_id"
              :options="selectOptions.categories"
              @selectOption="selectOptionCategory"
            />
            <p v-if="invalidMessage.category" class="text-error">
              *{{ invalidMessage.category }}
            </p>
          </div>
          <div class="text-start flex-auto" style="flex: 1 1 45%">
            <label style="font-size: 14px; font-weight: 500"
            >Education</label
            >
            <DropdownSelect
              placeholder="Choose a education"
              :defaultSelected="AIInfo.education"
              :options="selectOptions.educations"
              @selectOption="selectOptionEducation"
            />
            <p v-if="invalidMessage.education" class="text-error">
              *{{ invalidMessage.education }}
            </p>
          </div>
          <FLInput
            style="flex: 1 1 45%"
            inlineStyle
            label="Character Name"
            :error="invalidMessage.name"
            :hint="`${AIInfo.name.length || 0}/30 characters`"
          >
            <input
              v-model="AIInfo.name"
              @blur="AIInfo.name = AIInfo.name.trim()"
              minlength="1"
              maxlength="30"
              placeholder="Enter your text"
            />
          </FLInput>

          <FLInput
            style="flex: 1 1 45%"
            inlineStyle
            label="Inspired character"
            :error="invalidMessage.inspired_character"
            :hint="`${
                            AIInfo.inspired_character.length || 0
                        }/20 characters`"
          >
            <input
              v-model="AIInfo.inspired_character"
              @blur="
                                AIInfo.inspired_character =
                                    AIInfo.inspired_character.trim()
                            "
              minlength="1"
              maxlength="20"
              placeholder="Enter your text"
            />
          </FLInput>

          <FLInput
            style="flex: 1 1 45%"
            inlineStyle
            label="Total Chats"
            :error="invalidMessage.number_used"
          >
            <input
              v-model="AIInfo.number_used"
              type="number"
              step="1"
              placeholder="Example: 12, 120, 1200, 12000, 120000, 1200000,..."
              @input="validateIntegerInput"
            />
          </FLInput>
        </div>

        <!-- Country -->
        <div style="width: 50%">
          <SelectCountry
            :defaultSelected="{
                            continents: AIInfo.continents,
                            country: AIInfo.country,
                        }"
            @on-change="onChangeCountry"
          />
        </div>

        <div class="information-languages">
          <!-- <div class="warning">
              <img
                  src="@/assets/images/icons/svg/warning.svg"
                  alt="warning"
              />
              <span
                  >The following content needs to be translated into
                  multiple languages.</span
              >
          </div> -->

          <!-- Menu language -->
          <MenuLanguagesSelection
            :menuSelections="informationOptions.language"
            :loading="loading.update || loading.translate"
            :languageSelected="languageSelected.title"
            :checkList="checkListLanguage"
            @handleTranslate="handleTranslate"
            @handleSelectLanguage="handleSelectLanguage"
          />

          <div class="input-box-information-translate w-100">
            <template v-for="(value, key) in fieldsTranslate as {}">
              <div class="text-start gap-3 w-100">
                <div class="input-control">
                  <label
                    style="
                                            font-size: 14px;
                                            font-weight: 500;
                                        "
                  >{{ value }}</label
                  >
                  <textarea
                    v-if="key === 'description'"
                    class="form-control w-100"
                    v-model="
                                            AIInfo.description[
                                                languageSelected.description
                                            ]
                                        "
                    @blur="
                                            AIInfo.title[
                                                languageSelected.description
                                            ] =
                                                AIInfo.title[
                                                    languageSelected.description
                                                ].trim()
                                        "
                    maxlength="1000"
                    rows="4"
                    placeholder="Enter your text"
                  ></textarea>
                  <input
                    v-else
                    class="form-control w-100"
                    type="text"
                    v-model="
                                            AIInfo[key][languageSelected.title]
                                        "
                    @blur="
                                            AIInfo[key][
                                                languageSelected.title
                                            ] =
                                                AIInfo[key][
                                                    languageSelected.title
                                                ].trim()
                                        "
                    :maxlength="getMaxCharacterWithKey(key)"
                    placeholder="Enter your text"
                  />

                  <p class="alert-max-length">
                    {{
                      AIInfo[key][languageSelected.title]
                        .length || 0
                    }}/{{ getMaxCharacterWithKey(key) }}
                    characters
                  </p>
                </div>
              </div>
            </template>
          </div>
        </div>
      </template>
    </AccordingPanel>

    <!-- System prompt -->
    <AccordingPanel
      idTarget="ai-character-system-prompt"
      className=" small-button"
    >
      <template #title>
        <div class="according-header">
          <span class="according-header__title"> System prompt </span>
        </div>
      </template>
      <template #content>
        <div class="input-control">
          <label
            style="
                            font-size: 14px;
                            font-weight: 500;
                            text-align: left;
                        "
          >General of character prompt</label
          >
          <textarea
            class="form-control w-100"
            v-model="AIInfo.system_prompt"
            @blur="
                            AIInfo.system_prompt = AIInfo.system_prompt.trim()
                        "
            maxlength="3000"
            aria-label="Text input with dropdown button"
            placeholder="Enter your text"
            rows="4"
          ></textarea>

          <p class="alert-max-length">
            {{ AIInfo.system_prompt.length || 0 }}/3000 characters
          </p>
        </div>
      </template>
    </AccordingPanel>

    <!-- Skills -->
    <AccordingPanel idTarget="ai-character-skill" className=" small-button">
      <template #title>
        <div class="according-header">
          <span class="according-header__title">Skills </span>
          <span
            class="text-gray"
            style="font-weight: 400; font-size: 14px"
          >(Maximum 5 points)</span
          >
        </div>
      </template>
      <template #content>
        <div
          class="d-flex justify-content-between align-items-end gap-3"
        >
          <template v-for="(value, key) of skills">
            <div class="input-control w-100">
              <div class="text-start" style="width: 100%">
                <label
                  style="font-size: 14px; font-weight: 500"
                >{{ value }}</label
                >
                <select
                  v-model="AIInfo.skills[key]"
                  class="select-option"
                >
                  <option :value="0" selected>0</option>
                  <option :value="1">1</option>
                  <option :value="2">2</option>
                  <option :value="3">3</option>
                  <option :value="4">4</option>
                  <option :value="5">5</option>
                </select>
              </div>
              <div class="hint">{{ AIInfo.skills[key] }}/5</div>
            </div>
          </template>
        </div>
      </template>
    </AccordingPanel>

    <!-- Video call -->
    <AccordingPanel
      v-if="loading.video_call"
      idTarget="ai-character-video-call"
      className=" small-button"
      :disabled = "!personAiEditId "
      :isCheckboxChecked="isCheckboxChecked"
    >
      <template #title>
        <label class="custom-checkbox">
          <input
            type="checkbox"
            v-model="isCheckboxChecked"
            style="display: none"
            @change="handleCheckboxChange"
          />
          <img
            v-if="isCheckboxChecked"
            src="@/assets/images/icons/svg/check-input.svg"
            style="cursor: pointer"
          />
          <img
            v-else
            src="@/assets/images/icons/svg/uncheck-input.svg"
            style="cursor: pointer"
          />
          <span class="label-text">
              <strong style="font-size: 16px;">VIDEO CALL</strong>
              <strong style="font-size: 14px;">
                (Please complete creating your character before setting up video calls)
              </strong>
            </span>
        </label>
      </template>
      <template  v-if="isCheckboxChecked && personAiEditId"  #content>
        <div class="active-video-call">
          <span> Active Roleplay feature (Tutor Session) </span>
          <Toggle
            v-model="AIInfo.is_video_call_enabled"
            :value="AIInfo.is_video_call_enabled"
            class="toggle-width"
            style="transform: scale(1.5)"
          />
        </div>
        <div class="d-grid gap-4">
          <FLInput
            label="AI Video Prompt"
            :error="invalidMessage.video_call_prompt"
            :hint="`${
                            AIInfo.video_call_configs.prompt?.length ?? 0
                        } /3000`"
          >
                        <textarea
                          v-model="AIInfo.video_call_configs.prompt"
                          @blur="
                                AIInfo.video_call_configs.prompt =
                                    AIInfo.video_call_configs.prompt.trim()
                            "
                          maxlength="3000"
                          class="form-control"
                          placeholder="Enter your text"
                          rows="3"
                        ></textarea>
          </FLInput>

          <div
            class="text-start d-flex flex-column"
            style="width: 100%"
          >
            <label  style="font-size: 14px">
              <strong style="font-weight: bold">Chat model</strong>
              <strong style="font-weight: 500">
                (This model is not applicable for welcome message.)
              </strong>
            </label>
            <DropdownSelectChatModel
              :defaultSelected="AIInfo.video_call_configs.chat_model"
              :options="selectOptions.video_call_chat_models"
              @selectOption="selectOptionVideoCallChatModel"
            />
            <p
              v-if="invalidMessage.video_call_chat_models"
              class="text-error"
            >
              *{{ invalidMessage.video_call_chat_models }}
            </p>
          </div>


          <div
            class="text-start d-flex flex-column "
            style="width: 100%; gap: 10px; margin-top: 15px; margin-bottom: 15px"
          >
            <label class="parameter-label">
              Parameters
            </label>
            <div class="radio-group">
              <label class="radio-label" @click="handleSelectOption('default')">
                <img
                  v-if="selectOption === 'default'"
                  src="@/assets/images/icons/svg/check-radio.svg"
                  style="cursor: pointer"
                />
                <img
                  v-if="selectOption !== 'default'"
                  src="@/assets/images/icons/svg/uncheck-radio.svg"
                  style="cursor: pointer"
                />
                <input
                  type="radio"
                  style="display: none"
                  :checked="selectOption === 'default'"
                />
                Default
              </label>

              <label class="radio-label" @click="handleSelectOption('custom')">
                <img
                  v-if="selectOption === 'custom'"
                  src="@/assets/images/icons/svg/check-radio.svg"
                  style="cursor: pointer"
                />
                <img
                  v-if="selectOption !== 'custom'"
                  src="@/assets/images/icons/svg/uncheck-radio.svg"
                  style="cursor: pointer"
                />
                <input
                  type="radio"
                  style="display: none"
                  :checked="selectOption === 'custom'"
                />
                Custom
              </label>
            </div>

            <div v-if="selectOption === 'custom'" class="parameter-component">
              <div class="parameter-item">
                <div class="parameter-card">
                  <div class="parameter-card__left">
                    <p class="parameter-item-label">
                      Temperature
                    </p>
                    <p class="parameter-item-content">
                      Controls diversity via nucleus sampling: 0.5 means half of all likelihood-weighted options are considered. (0-1)
                    </p>
                  </div>
                  <FLInput
                    class="parameter-card__right"
                    inlineStyle
                  >
                    <input
                      v-model="AIInfo.video_call_configs.temperature"
                      type="number"
                      min="0"
                      max="1"
                      step="0.01"
                      @input="validateParameter"
                    />
                  </FLInput>
                </div>
              </div>

              <div class="parameter-item">
                <div class="parameter-card">
                  <div class="parameter-card__left">
                    <p class="parameter-item-label">
                      Max Tokens
                    </p>
                    <p class="parameter-item-content">
                      The maximum number of tokens to generate. Requests can use up to 8192 tokens shared between prompt and completion. (0 - 4096)                  </p>
                  </div>
                  <FLInput
                    class="parameter-card__right"
                    inlineStyle
                  >
                    <input
                      v-model="AIInfo.video_call_configs.max_token"
                      type="number"
                      min="0"
                      max="4096"
                      @input="validateParameter"
                    />
                  </FLInput>
                </div>
              </div>

              <div class="parameter-item">
                <div class="parameter-card">
                  <div class="parameter-card__left">
                    <p class="parameter-item-label">
                      Top P
                    </p>
                    <p class="parameter-item-content">
                      Controls randomness: lowering results in less random completions. As the temperature approaches zero, the model will become deterministic and repetitive. (0-1)                  </p>
                  </div>
                  <FLInput
                    class="parameter-card__right"
                    inlineStyle
                  >
                    <input
                      v-model="AIInfo.video_call_configs.top_p"
                      type="number"
                      min="0"
                      max="1"
                      step="0.01"
                      @input="validateParameter"
                    />
                  </FLInput>
                </div>
              </div>
            </div>
          </div>

          <div
            class="text-start d-flex flex-column"
            style="width: 100%"
          >
            <div class="title-voice">
              <label style="font-size: 14px; font-weight: 500">
                AI voice
              </label>
              <div class="d-flex flex-row gap-2">
                <label>
                  <input
                    type="checkbox"
                    v-model="isSyncModalVoice"
                    style="display: none"
                  />
                  <img
                    v-if="isSyncModalVoice"
                    src="@/assets/images/icons/svg/check-input.svg"
                    style="cursor: pointer"
                  />
                  <img
                    v-else
                    src="@/assets/images/icons/svg/uncheck-input.svg"
                    style="cursor: pointer"
                  />
                </label>
                <p>Use the same model</p>
              </div>
            </div>
            <div class="ai-voice-container">
              <div
                class="ai-voice-item"
                v-for="(item, index) in voicesData as {}"
                :key="index"
              >
                <p>
                  {{ index === "Polish" ? "Poland" : index }}
                </p>
                <select
                  v-model="AIInfo.video_call_configs.voices[index]"
                  @change="handleSelectVoice"
                  class="select-option"
                >
                  <optgroup
                    v-for="(_item, _index) in item"
                    :key="_index"
                    :label="_index.toString()"
                  >
                    <option
                      v-for="(__item, __index) in _item"
                      :key="__index"
                      :value="__item"
                    >
                      {{ __item }}
                    </option>
                  </optgroup>
                </select>
                <p
                  v-if="invalidVoiceData[index]"
                  class="text-error"
                >
                  *{{ invalidVoiceData[index] }}
                </p>
              </div>
            </div>
          </div>

          <!-- Video modal config -->
          <div class="text-start" style="width: 100%">
            <label style="font-size: 14px; font-weight: 500">
              Video
            </label>

            <div class="d-flex justify-content-start gap-3">
              <template
                v-for="value in [
                                    'listening_video',
                                    'responding_video',
                                ]"
              >
                <div
                  class="d-flex flex-column"
                  style="position: relative"
                >
                  <div
                    class="video-items"
                    style="margin-top: 8px"
                  >
                    <video
                      v-if="
                                                AIInfo.video_call_configs[value]
                                            "
                      class="videoVisible"
                      controls
                    >
                      <source
                        :src="
                                                    AIInfo.video_call_configs[
                                                        value
                                                    ]
                                                "
                        type="video/mp4"
                      />
                      Your browser does not support the
                      video tag.
                    </video>
                    <label v-else class="card-video">
                      <input
                        type="file"
                        accept=".mp4, .ogg, .webM"
                        @change="
                                                    onFileSelect(
                                                        $event,
                                                        value ===
                                                            'listening_video'
                                                            ? 'listening'
                                                            : 'responding'
                                                    )
                                                "
                      />
                      <i
                        class="bi-camera-video d-flex justify-content-center"
                        style="
                                                    font-size: 24px;
                                                    color: #adadad;
                                                "
                      ></i>
                      <p style="color: #adadad">
                        Upload video
                      </p>
                    </label>
                  </div>
                  <div
                    v-if="AIInfo.video_call_configs[value]"
                    style="
                                            position: absolute;
                                            top: 15px;
                                            right: 15px;
                                        "
                    @click="
                                            handleDeleteVideo(
                                                value === 'listening_video'
                                                    ? 'listening'
                                                    : 'responding'
                                            )
                                        "
                  >
                    <img
                      src="@/assets/images/icons/svg/delete-white.svg"
                      style="cursor: pointer"
                    />
                  </div>
                  <p
                    class="text-center"
                    style="
                                            font-size: 14px;
                                            font-weight: 500;
                                            padding-top: 4px;
                                        "
                  >
                    {{
                      value === 'listening_video'
                        ? 'Listening video'
                        : 'Responding video'
                    }}
                  </p>
                  <p
                    v-if="invalidMessage[value]"
                    class="text-error"
                  >
                    *{{ invalidMessage[value] }}
                  </p>
                </div>
              </template>
            </div>
          </div>

          <!-- Story of Character -->
          <div class="">
            <FLInput
              label="Story of character"
              :error="invalidMessage.story_of_character"
              :hint="`${
                                AIInfo.video_call_configs.story_of_character
                                    ?.length ?? 0
                            } /3000`"
            >
                            <textarea
                              v-model="
                                    AIInfo.video_call_configs.story_of_character
                                "
                              @blur="
                                    AIInfo.video_call_configs.story_of_character =
                                        AIInfo.video_call_configs.story_of_character?.trim() ||
                                        ''
                                "
                              maxlength="3000"
                              class="form-control"
                              placeholder="Enter your text"
                              rows="3"
                            ></textarea>
            </FLInput>
          </div>

          <!--                    <div-->
          <!--                        class="text-start d-flex flex-column gap-2"-->
          <!--                        style="width: 100%"-->
          <!--                    >-->
          <!--                        <div class="d-flex flex-row justify-content-between">-->
          <!--                            <label style="font-size: 14px; font-weight: 500">-->
          <!--                                Scenarios-->
          <!--                            </label>-->
          <!--                        </div>-->
          <!--                        <div v-if="false" class="video-topics">-->
          <!--                            <div-->
          <!--                                class="d-flex flex-row gap-2"-->
          <!--                                v-for="(item, index) in topicsData"-->
          <!--                                :key="index"-->
          <!--                            >-->
          <!--                                <label>-->
          <!--                                    <input-->
          <!--                                        type="checkbox"-->
          <!--                                        v-model="item.isChecked"-->
          <!--                                        style="display: none"-->
          <!--                                    />-->
          <!--                                    <img-->
          <!--                                        v-if="item.isChecked"-->
          <!--                                        src="@/assets/images/icons/svg/check-input.svg"-->
          <!--                                        style="cursor: pointer"-->
          <!--                                    />-->
          <!--                                    <img-->
          <!--                                        v-else-->
          <!--                                        src="@/assets/images/icons/svg/uncheck-input.svg"-->
          <!--                                        style="cursor: pointer"-->
          <!--                                    />-->
          <!--                                </label>-->
          <!--                                <p>{{ item?.name }}</p>-->
          <!--                            </div>-->
          <!--                        </div>-->
          <!--                        <p-->
          <!--                            v-if="invalidMessage.video_call_video_topics"-->
          <!--                            class="text-error"-->
          <!--                        >-->
          <!--                            *{{ invalidMessage.video_call_video_topics }}-->
          <!--                        </p>-->
          <!--                    </div>-->

          <div class="text-start" style="width: 100%; max-width: 100% ">
            <div class="video-topic-container">
              <label
                v-if="selectedTopics.length > 0"
                style="font-size: 14px; font-weight: 500; margin-bottom: 20px"
              >
                Scenarios
              </label>
              <template
                v-for="(item, index) in selectedTopics"
                :key="index"
              >
                <div
                  class="topic-item"
                  v-if="item?.scenarios?.length > 0"
                >
                  <div
                    class="d-flex flex-row justify-content-between w-100"
                    style="
                                        font-weight: 600;
                                        margin-bottom: 10px;
                                    "
                  >
                    <p>{{ item?.name }}</p>
                    <!-- <i
                    class="bi bi-pencil"
                    style="cursor: pointer"
                    title="Edit this is scenario"
                    @click="handleOpenModalScenarioTopic(item)"
                ></i> -->
                  </div>

                  <div
                    class="d-flex flex-column"
                    style="
                  padding: 0 16px;
                  gap: 8px;
                  width: 100%;"
                  >
                    <div
                      v-for="(_item, _index) in item?.scenarios"
                      :key="_index"
                      class="item"
                    >
                      <div v-if="_item.type ==='learn_language'  ">
                        <img
                          src="@/assets/images/icons/svg/learn-icon.svg"
                        />
                      </div>
                      <div v-else>
                        <img
                          src="@/assets/images/icons/svg/entertainment-icon.svg"
                        />
                      </div>
                      <p
                        class="text-start"
                        :class="
                                                _item?.is_published
                                                    ? ''
                                                    : 'color-warning font-italic'
                                            "
                      >
                        {{ _item?.name }}
                      </p>
                      <Popover
                        ref="refElPopover"
                        placement="bottom"
                      >
                        <template #toggle-open-popover>
                          <span
                            class="icon-toggle-options"
                          >
                            <Dots3Icon />
                          </span>
                        </template>
                        <template #popover-content>
                          <div class="item__actions">
                            <button
                              class="action__edit"
                              @click=" handleEditScenario(_item.id)">
                              <EditPenIcon />
                              <span>Edit scenario</span>
                            </button>
                            <button class="action__remove">
                              <DeleteIcon />
                              <span @click="openRemoveScenarioModal(_item.id)">Delete</span>
                            </button>
                          </div>
                        </template>
                      </Popover>
                    </div>
                    <!-- <NoData
                    v-else
                    textBtn="Add scenarios"
                    @click="handleOpenModalScenarioTopic(item)"
                /> -->
                  </div>
                </div>
              </template>

              <!-- Unknown Scenario -->
              <div
                v-if="
                                scenarioUnknownTopic &&
                                scenarioUnknownTopic?.length > 0
                            "
                class="topic-item scenario-unknown-topic"
              >
                <div
                  class="d-flex flex-row justify-content-between w-100"
                  style="font-weight: 600; margin-bottom: 10px"
                >
                  <p>Unknown</p>
                </div>
                <div
                  class="d-flex flex-column"
                  style="padding: 0 16px; gap: 8px; width: 100%"
                >
                  <div
                    v-for="(item, idx) in scenarioUnknownTopic"
                    :key="idx"
                    class="item"
                  >
                    <img
                      src="@/assets/images/icons/svg/lorem.svg"
                    />
                    <p class="text-start">
                      {{ item?.name }}
                    </p>
                    <EditPenIcon
                      class="bi bi-pencil icon-edit"
                      style="cursor: pointer"
                      @click="
                                            handleOpenModalUpdateTopicForScenarioUnknownTopic(
                                                item
                                            )
                                        "
                    ></EditPenIcon>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </AccordingPanel>
    <div
      v-else-if="!loading.video_call && environment !== 'STAG'"
      class="edit-container"
    >
      <div
        class="me-2 spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></div>
    </div>
  </div>
</template>

