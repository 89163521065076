<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.8335 7.83325H10.3335"
      stroke="currentColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.8335 12H16.1668"
      stroke="currentColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2.83333 12C2.83333 14.0397 2.94371 15.6219 3.21794 16.8559C3.49006 18.0803 3.91232 18.9101 4.50113 19.4989C5.08995 20.0877 5.91971 20.5099 7.14415 20.7821C8.37805 21.0563 9.96027 21.1667 12 21.1667C12.8979 21.1667 13.7102 21.1454 14.4457 21.0974C17.0727 20.926 18.5778 20.4199 19.4989 19.4989C20.4199 18.5778 20.926 17.0727 21.0974 14.4457C21.1454 13.7102 21.1667 12.8979 21.1667 12C21.1667 9.96027 21.0563 8.37805 20.7821 7.14415C20.5099 5.91971 20.0877 5.08995 19.4989 4.50113C18.9101 3.91232 18.0803 3.49006 16.8558 3.21794C15.6219 2.94371 14.0397 2.83333 12 2.83333C9.96027 2.83333 8.37805 2.94371 7.14415 3.21794C5.91971 3.49006 5.08995 3.91232 4.50113 4.50113C3.91232 5.08995 3.49006 5.91971 3.21794 7.14415C2.94371 8.37805 2.83333 9.96027 2.83333 12Z"
      stroke="currentColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>

</template>
