import { CalendarIcon, DeleteIcon, Dots3Icon, EditPenIcon } from "@/assets/images/icons/components";
import { AccordingPanel, DropdownSelect, FLForm, MenuLanguagesSelection, Popover } from "@/components/base";
import { DropdownSelectChatModel } from "@/components/common";
import ModalBase from "@/components/base/modal-base/modal-base.vue";
import {
    ModalCategory,
    ModalScenarioTopic,
    ModalTopicVideoCall,
    ModalUpdateTopicForScenario,
} from "@/components/base/modals";

import ShowTranslateLanguage from "@/components/base/modals/show-translate-language/show-translate-language.vue";
import NoData from "@/components/base/no-data/no-data.vue";
import PersonAisConstants from "@/constants/person-ai";
import PersonAiServices from "@/services/person-ai";
import { TransformedActionTypesPersonAi } from "@/store/modules/person-ai/action-style";
import { TransformedGetterTypesPersonAi } from "@/store/modules/person-ai/getter-style";
import { TransformedMutationTypesPersonAi } from "@/store/modules/person-ai/mutation-style";
import { TransformedMutationTypesTable } from "@/store/modules/table/mutation-style";
import Toggle from "@vueform/toggle";
import _ from "lodash";
import { Options, Vue } from "vue-class-component";
import SelectCountry from "./components/select-country.vue";
import { checkValidateForm } from "./utils/validate";
import { ROUTE_NAMES } from "@/constants/routeNames";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

const { FLInput } = FLForm;
@Options({
    components: {
        ShowTranslateLanguage,
        ModalBase,
        DropdownSelect,
        DropdownSelectChatModel,
        Toggle,
        DeleteIcon,
        Dots3Icon,
        Popover,
        AccordingPanel,
        FLInput,
        MenuLanguagesSelection,
        ModalTopicVideoCall,
        ModalScenarioTopic,
        ModalCategory,
        ModalUpdateTopicForScenario,
        NoData,
        EditPenIcon,
        SelectCountry,
        CalendarIcon
    },
    props: {},

    emits: ["createAi"],
    provide() {
        return {
            updateTopicData: () => {
                this.apiGetVideoCallTopics();
            },
        };
    },

    watch: {
        AIInfo: {
            handler(newVal, oldValue) {
                // this.handleCheckValid();
                this.computedCheckListLanguage();
            },
            deep: true,
        },
        topicsData: {
            handler(newVal, oldValue) {
                this.selectedTopics = newVal.filter((topic: any) => {
                    return topic.isChecked === true;
                });
                this.AIInfo.video_call_configs.topic_ids =
                    this.selectedTopics.map((topic: any) => topic.id);
                this.selectedDefault = this.AIInfo.status === "Published" ? "publish" : "save_draft";
                localStorage.setItem('selectedOption', this.selectedDefault.value);
            },
            deep: true,
        },
    },
})
export default class CreateAiCharacter extends Vue {
    public personAiEditId: number | null = null;
    public unsubscribe!: any;
    public PersonAiServices: any = PersonAiServices;
    public PersonAisConstants: any = new PersonAisConstants();
    public translateModalArray: any = [];
    public originData: any = JSON.stringify(new PersonAisConstants().dataInit);
    public AIInfo: any = new PersonAisConstants().dataInit;
    public readyToSave: boolean = false;
    public messageValidateForm: string | null = null;
    public languageSelected: any = new PersonAisConstants().languageSelected;
    public environment = process.env.VUE_APP_ENV;
    public selectedEditTopic: any = null;
    public selectedTopics: any = [];
    public scenarioIdRemove: number | null = null;
    public scenarioUnknownTopicSelected: any = null;
    public topicsData: any = [];
    public voicesData: any = null;
    public invalidVoiceData: any = {};
    public invalidParameters: any = {};
    public toggleVideoMode: boolean = false;
    public invalidMessage: any = new PersonAisConstants().invalidMessage;
    public skills: any = PersonAisConstants.skills;
    public fieldsTranslate: any = PersonAisConstants.fieldsTranslate;
    public fieldsImageProfile: any =
        PersonAisConstants.imagesProfileAiCharacter;
    public checkListLanguage: any = [];
    public scenarioUnknownTopic: any = null;
    public isSyncModalVoice: boolean = false;
    public isUpdateOnServer: boolean = false;
    public isCheckedVideoConfig: string | null = null;
    private store = useStore();
    private router = useRouter();
    public isCheckboxChecked: boolean = false;
    selectedValue: string | null = null;
    currentPage: number = 1;
    selectOption: string = 'default';
    public informationOptions: any = {
        language: [
            "English",
            "German",
            "Spanish",
            "Polish",
            "Italian",
            "French",
            "Portuguese",
            "Hindi",
            "Korean",
            "Japanese",
        ],
    };

    public payloadAvatar = new FormData();

    public loading: any = {
        saveDraft: false,
        public: false,
        titleTranslate: false,
        descriptionTranslate: false,
        quoteTranslate: false,
        welcomeMessageTranslate: false,
        question1Translate: false,
        question2Translate: false,
        question3Translate: false,
        video_call: false,
    };

    OptionItems = [
        {
            value: "save_draft",
            text: "Save Draft",
            active: true,
        },
        {
            value: "publish",
            text: "Publish",
            active: true,
        },
    ];

    public savedSelection = localStorage.getItem('selectedOption');
    public selectedDefault = this.savedSelection ||this.AIInfo.status === this.OptionItems[1].text ? this.OptionItems[1].value: this.OptionItems[0].value

    public selectedItem(selectedValue: string) {
        this.selectedDefault = selectedValue
        localStorage.setItem('selectedOption', selectedValue);
    }


    public selectOptions: any = {
        characterTypes: [
            {
                value: "chat",
                text: "Chat",
                active: true,
            },
            {
                value: "diary",
                text: "Diary",
                active: false,
            },
            {
                value: "plugin",
                text: "Plugin",
                active: false,
            },
        ],
        categories: [],
        educations: [
            {
                value: "Elementary School",
                text: "Elementary School",
                active: true,
            },
            {
                value: "Middle School",
                text: "Middle School",
                active: true,
            },
            {
                value: "High School",
                text: "High School",
                active: true,
            },
        ],
        video_call_chat_models: [],
    };

    // =============== Lifecycle Hooks =============== //
    async beforeMount() {
        this.personAiEditId =
            this.$store.getters[
                TransformedGetterTypesPersonAi.GET_PERSON_AI_EDIT_ID
                ];
        // this.apiGetCategoryList();
        if (this.personAiEditId) {
            await this.apiGetPersonAiInfo(this.personAiEditId);
            if (this.AIInfo.video_call_configs?.prompt)
            {
                this.loadStateFromLocalStorage()
                this.loadStateFromLocalStorageParameter()
            }
        }
        else {
            this.isCheckboxChecked = false;
            this.selectOption = 'default'
        }
        this.apiGetCategoryList();
        await this.apiGetAIVoice();
        this.apiGetVideoCallTopics();
    }

    async mounted() {
        this.apiGetAIChatModel();

        const queryPage = this.$route.query.page;
        this.currentPage = queryPage ? parseInt(queryPage as string, 10) : 1;
    }

    private loadStateFromLocalStorage() {
        const savedState = localStorage.getItem('isCheckboxChecked');
        if (savedState !== null) {
            try {
                this.isCheckboxChecked = false
                const parsedState = JSON.parse(savedState);

                if (typeof parsedState === 'boolean') {
                    this.isCheckboxChecked = parsedState;
                } else {

                    console.warn('Stored value is not a boolean:', parsedState);
                    this.isCheckboxChecked = false;
                    CreateAiCharacter.saveStateToLocalStorage(false); // Sync false state to localStorage
                }
            } catch (e) {
                console.error('Error parsing stored value:', e);
                this.isCheckboxChecked = false;
                CreateAiCharacter.saveStateToLocalStorage(false); // Sync false state to localStorage
            }
        } else {
            this.isCheckboxChecked = false;
            CreateAiCharacter.saveStateToLocalStorage(false); // Sync false state to localStorage
        }
    }

    private loadStateFromLocalStorageParameter() {
        const defaultValue = this.AIInfo.video_call_configs.temperature;
        if(! defaultValue) {
            this.selectOption = 'default';
            CreateAiCharacter.saveStateToLocalStorageParameter('default');
        }

        else {
            this.selectOption = 'custom';
            CreateAiCharacter.saveStateToLocalStorageParameter('custom');

        }
    }

    private static saveStateToLocalStorage(state: boolean) {
        localStorage.setItem('isCheckboxChecked', JSON.stringify(state));
    }

    private static saveStateToLocalStorageParameter(state: string) {
        localStorage.setItem('selectOption', JSON.stringify(state));
    }



    // =============== Event Form =============== //
    public selectOptionType(value: any) {
        this.AIInfo.type = value;
    }
    public selectOptionCategory(value: any) {
        this.AIInfo.category_id = value;
    }
    public selectOptionEducation(value: any) {
        this.AIInfo.education = value;
    }
    public selectOptionVideoCallChatModel(value: any) {
        this.AIInfo.video_call_configs.chat_model = value;
    }
    public onChangeCountry(value: { continents: string; country: string }) {
        this.AIInfo.continents = value.continents;
        this.AIInfo.country = value.country;
    }
    public handleSelectLanguage(title: string) {
        this.languageSelected = {
            description: title,
            guideline_next_question_1: title,
            guideline_next_question_2: title,
            guideline_next_question_3: title,
            quote: title,
            title: title,
            welcome_messages: title,
        };
    }
    public handleSelectVoice(e: any) {
        if (this.isSyncModalVoice)
            for (let k in this.voicesData) {
                this.AIInfo.video_call_configs.voices[k] = e?.target?.value;
            }
    }

    public async handleSelectOption(option: string) {
        this.selectOption = option;
        localStorage.setItem('selectOption', this.selectOption);
    }

    public async handleCheckboxChange(e: any) {
        localStorage.setItem('isCheckboxChecked', JSON.stringify(this.isCheckboxChecked));
        await this.$nextTick();
        this.$forceUpdate();
    }

    public async handleAvatar(event: Event, type: string) {
        this.payloadAvatar = new FormData();
        const selectedFiles = (event.target as HTMLInputElement).files;
        if (selectedFiles) {
            const file = selectedFiles[0];
            if (
                file.type === "image/png" ||
                file.type === "image/jpeg" ||
                file.type === "image/jpg" ||
                file.type === "image/gif"
            ) {
                this.payloadAvatar.set(`image`, file);
                this.payloadAvatar.set(`field`, type);
                this.apiUpdateAvatar(type);
            } else this.$toast.error("Invalid file");
        }
    }
    public onFileSelect(event: any, type: any) {
        const file = event.target.files[0];

        if (file && file.type === "video/mp4") {
            const reader = new FileReader();
            if (type === "listening") {
                reader.onload = () => {
                    this.AIInfo.video_call_configs.listening_video =
                        reader.result;
                };
            } else if (type === "responding") {
                reader.onload = () => {
                    this.AIInfo.video_call_configs.responding_video =
                        reader.result;
                };
            }
            reader.readAsDataURL(file);
            this.apiUpdateVideo(type, file);
        } else {
            alert("Please select a valid MP4 file.");
        }
    }

    public handleCheckValid() {
        this.readyToSave = true;
        this.handleResetInvalidMessage();
        this.messageValidateForm = checkValidateForm(
            this.AIInfo,
            this.invalidMessage,
            this.readyToSave,
            this.isCheckboxChecked,
        );

        if(this.isCheckboxChecked){
            for (const voice in this.voicesData) {
                if (this.personAiEditId && !this.AIInfo.video_call_configs.voices[voice]) {
                    this.invalidVoiceData[voice] = "Voice is required";
                    this.messageValidateForm = "Voice is required";
                    return;
                }
            }

            this.handleSaveParameterVideoCall()
        }
    }

    public handleSaveParameterVideoCall(){
        if(this.selectOption === 'custom'){
            const temp = this.AIInfo.video_call_configs.temperature;
            const maxToken = this.AIInfo.video_call_configs.max_token;
            const topP = this.AIInfo.video_call_configs.top_p;

            if(temp === null ){
                this.messageValidateForm = "Temperature is required"
                return
            }

            if(maxToken === null){
                this.messageValidateForm = "Max Tokens is required"
                return
            }

            if(topP === null){
                this.messageValidateForm = "Top P is required"
                return
            }
            return;
        }
        else {
            this.resetParameter();
        }
    }

    public resetParameter = () => {
        if (this.selectOption === 'default') {
            this.AIInfo.video_call_configs.temperature = null;
            this.AIInfo.video_call_configs.max_token = null;
            this.AIInfo.video_call_configs.top_p = null;
        } else if (this.selectOption === 'custom') {
            this.AIInfo.video_call_configs.temperature = 0;
            this.AIInfo.video_call_configs.max_token = 0;
            this.AIInfo.video_call_configs.top_p = 0;
            this.resetValidParameter()
        }
    }

    public resetValidParameter(){
        this.invalidParameters.temperature = null;
        this.invalidParameters.max_token = null;
        this.invalidParameters.top_p = null;
    }

    public validateParameter(){
        const temp = this.AIInfo.video_call_configs.temperature;
        const maxToken = this.AIInfo.video_call_configs.max_token;
        const topP = this.AIInfo.video_call_configs.top_p;

        if(this.selectOption === 'custom'){
            if(temp < 0  || temp > 1){
                if (temp < 0) {
                    this.AIInfo.video_call_configs.temperature = 0;
                } else if (temp > 1) {
                    this.AIInfo.video_call_configs.temperature = 1;
                }
            }

            if (!Number.isInteger(maxToken) || maxToken < 0 || maxToken > 4096) {
                if (maxToken < 0) {
                    this.AIInfo.video_call_configs.max_token = 0;
                } else if (maxToken > 4096) {
                    this.AIInfo.video_call_configs.max_token = 4096;
                } else {
                    this.AIInfo.video_call_configs.max_token = Math.round(maxToken);
                }
            }

            if(topP < 0 || topP > 1){
                if (topP < 0) {
                    this.AIInfo.video_call_configs.top_p = 0;
                } else if (topP > 1) {
                    this.AIInfo.video_call_configs.top_p = 1;
                }
            }
        }

        else {
            this.resetValidParameter()
        }
    }

    public handleResetInvalidMessage() {
        this.invalidMessage = new PersonAisConstants().invalidMessage;
        for (const key in this.voicesData) {
            this.invalidVoiceData[key] = "";
        }
    }

    public validateIntegerInput(){
        if (!Number.isInteger(this.AIInfo.number_used)) {
            this.AIInfo.number_used = Math.round(this.AIInfo.number_used);
        }
    }

    // =============== Action Button =============== //
    public handleRemoveImage(type: string) {
        this.AIInfo[`image_url_${type}`] = null;
    }
    public handleDeleteVideo(item: any) {
        if (item === "responding") {
            this.AIInfo.video_call_configs.responding_video = null;
            return;
        }
        if (item === "listening") {
            this.AIInfo.video_call_configs.listening_video = null;
            return;
        }
    }
    public handleTranslate() {
        if (!this.AIInfo["title"]?.English) {
            this.$toast.error("Invalid space input.");
            return;
        }
        const fieldTranslate = PersonAisConstants.infoSystemPromptTranslate;
        const payload: any = {};
        for (let key in fieldTranslate) {
            payload[key] = this.AIInfo[key].English;
        }
        this.apiTranslateText({ content: payload, non_english_raises: false });
    }
    public handleOpenModal(namRef: string) {
        (this.$refs[namRef] as any).openModal();
    }
    public handleOpenDeleteConfirm() {
        (this.$refs["modal-base-delete"] as any).openModal();
    }
    public handleDelete() {
        this.AIInfo = new PersonAisConstants().dataInit;
        this.languageSelected = new PersonAisConstants().languageSelected;
    }
    public async handleSaveDraft(isDraft = false) {
        this.messageValidateForm = null;
        if (!this.personAiEditId || isDraft) {
            this.loading.saveDraft = true;
            await this.handlePublic();
            this.loading.saveDraft = false;
        } else this.handlePublic();
    }

    public handleUpdate() {
        return this.selectedValue == "publish" ? this.handlePublic() : this.handleSaveDraft()
    }

    public async handlePublic() {
        this.AIInfo.status = this.loading.saveDraft ? "Draft" : "Published";
        try {
            await this.store.dispatch('saveCheckboxState', this.isCheckboxChecked);
            console.log('Checkbox state saved successfully');
        } catch (error) {
            console.error('Error saving checkbox state:', error);
        }
        this.handleCheckValid();

        if (!this.messageValidateForm) {
            if (!this.loading.saveDraft) this.loading.public = true;
            (await this.personAiEditId)
                ? this.apiUpdatePersonAi()
                : this.apiCreatePersonAi();
            this.messageValidateForm = null;
        } else {
            this.$toast.error(this.messageValidateForm);
        }
    }

    public handleGoBackWithoutSave() {
        this.handleDelete();
        this.readyToSave = false;
        this.handleDelete();

        if (this.personAiEditId)
            this.$store.commit(TransformedMutationTypesPersonAi.SET_EDIT_PERSON_AI_ID);
        else
            this.$store.commit(TransformedMutationTypesTable.SET_ADD_ACTION);
        if (this.isUpdateOnServer) this.$router.push("/ai-character");
        else this.$router.push("/ai-character");
        this.isUpdateOnServer = false;
    }

    public async handleGoBack() {
        let noChange = _.isEqual(this.AIInfo, JSON.parse(this.originData));

        if (noChange) {
            if (this.personAiEditId)
                this.$store.commit(
                    TransformedMutationTypesPersonAi.SET_EDIT_PERSON_AI_ID
                );
            else
                this.$store.commit(
                    TransformedMutationTypesTable.SET_ADD_ACTION
                );
            // this.$emit("createAi", false);

            if (this.isUpdateOnServer)
                this.$router.push({ path: '/ai-character', query: { page: this.currentPage } });
            else
                this.$router.replace(`/ai-character?page=${this.currentPage}`);
            this.isUpdateOnServer = false;
        } else (this.$refs["modal-base-confirm-goback"] as any).openModal();

        if(!this.AIInfo.video_call_configs?.chat_model) {
            await this.store.dispatch('clearCheckboxState');
            this.isCheckboxChecked = false;
        }

        if(!this.AIInfo.video_call_configs?.temperature){
            this.selectOption = 'default';
            CreateAiCharacter.saveStateToLocalStorageParameter('default');
        }

        this.router.back();
    }

    public handleEditScenario(id: number) {
        const routeData = this.$router.resolve({
            name: ROUTE_NAMES.SCENARIOS,
            query: { "scenario-id": id },
        });
        window.open(routeData.href, "_blank");
    }
    public handleRemoveScenario(id: number) {
        const routeData = this.$router.resolve({
            name: ROUTE_NAMES.SCENARIOS,
            query: { "scenario-id": id },
        });
        window.open(routeData.href, "_blank");
    }

    // =============== Action Modal =============== //
    // ----- Scenario Topic -----
    public handleOpenModalUpdateTopicForScenarioUnknownTopic(item: any) {
        this.handleOpenModal("modal-update-scenario-unknown-topic");
        this.scenarioUnknownTopicSelected = item;
    }
    public async handleOpenModalScenarioTopic(item: any) {
        this.handleOpenModal("modal-scenario-topic");
        this.selectedEditTopic = item;
    }
    public onCloseModalScenario() {
        this.selectedEditTopic = null;
    }
    public openRemoveScenarioModal(id: number) {
        this.scenarioIdRemove = id;
        (this.$refs["modal-base-delete-scenario"] as any).openModal();
    }

    // ----- Topic -----
    public async handleOpenModalTopicDetail(item: any) {
        await this.$store.commit(
            TransformedMutationTypesPersonAi.SET_TOPIC_DATA_SELECTED,
            item
        );
        (this.$refs["modal-topic-video-call"] as any).openModal();
    }
    // ----- Category -----
    public async handleOpenModalCategory() {
        (this.$refs["modal-category"] as any).openModal();
    }

    // =============== Api Action =============== //
    public async apiGetAIChatModel() {
        this.$apiAction.handleApiRequest(
            TransformedActionTypesPersonAi.GET_VIDEO_CALL_CHAT_MODELS,
            {},
            (data: any) => {
                for (let item of data) {
                    this.selectOptions.video_call_chat_models.push({
                        text: item.model,
                        value: item.model,
                        active: true,
                        provider: item.provider,
                    });
                }
            }
        );
    }
    public apiGetCategoryList() {
        this.$apiAction.handleApiRequest(
            TransformedActionTypesPersonAi.GET_CATEGORY_LIST,
            {},
            (data: any) => {
                for (let item of data) {
                    this.selectOptions.categories.push({
                        text: item.name,
                        value: item.id,
                        active: true,
                    });
                }
            }
        );
    }
    public async apiCreatePersonAi() {
        const { update_date, ...payload } = this.AIInfo;
        await this.$apiAction.handleApiRequest(
            TransformedActionTypesPersonAi.CREATE_PERSON_AI_V2,
            payload,
            (data: any) => {
                this.$toast.success(data.message);
                this.originData = JSON.stringify(this.AIInfo);
                setTimeout(() => {
                    this.loading.public = false;
                    this.isUpdateOnServer = true;
                    this.handleGoBackWithoutSave();
                }, 1000);
            },
            () => {
                this.readyToSave = false;
                this.loading.public = false;
            }
        );
    }
    public async apiUpdatePersonAi() {
        // console.log("update, ai");
        const { update_date, ...data } = this.AIInfo;
        const payload = {
            id: this.personAiEditId,
            ...data,
        };
        this.$apiAction.handleApiRequest(
            TransformedActionTypesPersonAi.UPDATE_PERSON_AI_DETAIL_V2,
            payload,
            (data: any) => {
                this.$toast.success(data.message);
                this.originData = JSON.stringify(this.AIInfo);
                setTimeout(() => {
                    this.loading.public = false;
                    this.isUpdateOnServer = true;
                    // this.handleGoBackWithoutSave();
                }, 1000);
            },
            () => {
                this.loading.public = false;
            }
        );
    }
    public async apiGetPersonAiInfo(id: number) {
        await this.$apiAction.handleApiRequest(
            TransformedActionTypesPersonAi.GET_PERSON_AI_INFO_V2,
            { id },
            (data: any) => {
                this.AIInfo = PersonAiServices.convertEditPayload(data);
                // this.originData = PersonAiServices.convertEditPayload(data);
                this.originData = JSON.stringify(
                    PersonAiServices.convertEditPayload(data)
                );
            }
        );
    }
    public async apiGetAIVoice() {
        this.$apiAction.handleApiRequest(
            TransformedActionTypesPersonAi.GET_VIDE0_CALL_VOICES,
            {},
            (data: any) => {
                this.voicesData = data;
                for (const key in this.voicesData) {
                    this.invalidVoiceData[key] = "";
                }
            }
        );
    }
    public async apiGetVideoCallTopics() {
        this.loading.video_call = false;
        this.$apiAction.handleApiRequest(
            TransformedActionTypesPersonAi.GET_VIDEO_CALL_TOPICS_V2,
            {
                person_ai_id: this.personAiEditId || null,
                populate_scenario: true,
            },
            (data: any) => {
                this.topicsData = data.data;

                // Đặt isChecked của tất cả các đối tượng trong topicsData về false
                this.topicsData.forEach(
                    (topic: any) => (topic.isChecked = false)
                );

                // Kiểm tra và cập nhật isChecked cho các đối tượng có trong mảng topics
                this.AIInfo.video_call_configs.topic_ids.forEach(
                    (selectedTopic: any) => {
                        const foundTopic = this.topicsData.find(
                            (topic: any) => topic.id === selectedTopic
                        );
                        if (foundTopic) foundTopic.isChecked = true;
                    }
                );

                //Scenario Unknown topics
                if (data?.unknown?.length > 0) {
                    this.scenarioUnknownTopic = data.unknown;
                }

                this.loading.video_call = true;
            }
        );
    }
    public apiUpdateVideo(type: string, file: any) {
        const payload = new FormData();
        payload.append("video", file);
        this.$apiAction.handleApiRequest(
            TransformedActionTypesPersonAi.UPLOAD_VIDEO,
            payload,
            (data: any) => {
                this.AIInfo.video_call_configs[`${type}_video`] =
                    data.video_url;
            }
        );
    }
    public apiUpdateAvatar(type: string) {
        this.$apiAction.handleApiRequest(
            TransformedActionTypesPersonAi.UPLOAD_AVATAR,
            this.payloadAvatar,
            (data: any) => {
                this.AIInfo[`image_url_${type}`] = data.image_url;
            }
        );
    }
    public apiRemoveScenario() {
        this.$apiAction.handleApiRequest(
            TransformedActionTypesPersonAi.DELETE_SCENARIO_V3,
            {
                scenario_id: this.scenarioIdRemove,
            },
            (data: any) => {
                this.$toast.success("Remove scenario successfully");
                this.apiGetVideoCallTopics();
                this.scenarioIdRemove = null;
            },
            () => {
                this.scenarioIdRemove = null;
                this.$toast.console.error();
                ("Remove scenario fail");
            }
        );
    }
    public apiTranslateText(payload: any) {
        this.loading.translate = true;
        this.$apiAction.handleApiRequest(
            TransformedActionTypesPersonAi.AUTO_TRANSLATE_TEXT_V2,
            payload,
            (data: any) => {
                this.loading.translate = false;
                if (data["English"]) {
                    for (let k in PersonAisConstants.infoSystemPromptTranslate) {
                        for (const [l, value] of Object.entries(data) as any) {
                            this.AIInfo[k] = {
                                ...this.AIInfo[k],
                                [l]: value[k],
                            };
                        }
                    }
                }
            },
            () => {
                this.loading.translate = false;
            }
        );
    }

    // =============== Utilities Helper =============== //
    public computedCheckListLanguage() {
        const a = PersonAisConstants.infoSystemPromptTranslate;
        const checkListLanguage = [];

        for (const l of this.informationOptions.language) {
            for (const [key] of Object.entries(a)) {
                if (this.AIInfo[key][l]) {
                    checkListLanguage.push(l);
                    break;
                }
            }
        }
        this.checkListLanguage = checkListLanguage;
    }

    public getMaxCharacterWithKey(key: string): number {
        switch (key) {
            case "title":
                return 60;
            case "description":
                return 1000;
            case "quote":
                return 100;
            case "welcome_messages":
                return 120;
            default:
                return 100;
        }
    }
}
