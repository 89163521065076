<template>
  <div class="meeting">
    <div class="meeting-container">
      <div class="meeting-header">
        <div class="meeting-label">
          <div class="meeting-label-logo">
            <img
              src="@/assets/images/icons/png/google-meet.png"
              alt="Google Meet"
            />
          </div>
          <div class="meeting-label-title">Google Meet</div>
        </div>
        <button class="meeting-create" @click="createMeeting">
          <span>+</span>Create
        </button>
      </div>
      <div class="meeting-content">
        <div class="meeting-left">
          <div class="meeting-steps">
            <p class="meeting-steps-name">
              <span>Step 1:</span> Click the “Create” button to go to the
              calendar, set up a meeting in Google Meet, and copy the meeting
              link.
            </p>
            <div class="meeting-tutorial">
              <div class="meeting-tutorial-header" @click="toggleTutorial">
                <p>Tutorial</p>
                <ArrowDownIcon />
              </div>
              <div
                class="meeting-tutorial-content"
                :class="isOpenTutorial ? 'opened' : ''"
              >
                <img
                  src="@/assets/images/icons/png/gg-meeting-tutorial.png"
                  alt="Tutorial"
                />
              </div>
            </div>
          </div>
          <button
            class="meeting-create meeting-create-mb"
            @click="createMeeting"
          >
            <span>+</span>Create
          </button>
        </div>
        <div class="meeting-right">
          <div class="meeting-steps">
            <p class="meeting-steps-name">
              <span>Step 2:</span> Paste the meeting link here and preview the
              meeting details
            </p>
            <div class="meeting-tutorial">
              <div class="meeting-tutorial-header" @click="toggleCorretFormat">
                <p>Example of Correct Formatting</p>
                <ArrowDownIcon />
              </div>
              <div
                class="meeting-tutorial-content"
                :class="isOpenFormat ? 'opened' : ''"
              >
                <div class="meeting-tutorial-correctformat">
                  <span>Test Meeting</span>
                  <span>Wednesday, November 13 · 9:30 - 10:30pm</span>
                  <span>Time zone: Asia/Ho_Chi_Minh</span>
                  <span>Google Meet joining info</span>
                  <span
                    >Video call link: https://meet.google.com/hfz-igak-rnc</span
                  >
                </div>
              </div>
            </div>
          </div>
          <MeetingInput @update:meetingDetails="updateMeetingDetails" />
          <div v-if="meetingDetails" class="meeting-preview">
            <p class="meeting-preview-title">Preview Details:</p>
            <div class="meeting-preview-items">
              <p><span>Title:</span> {{ meetingDetails?.title }}</p>
              <p><span>Time:</span> {{ meetingDetails?.time_info }}</p>
              <p><span>Timezone:</span> {{ meetingDetails?.time_zone }}</p>
              <p><span>Link:</span> {{ meetingDetails?.meeting_link }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="meeting-content">
      <div class="meeting-header">
        <div class="meeting-label">
          <div class="meeting-label-logo">
            <img src="@/assets/images/icons/png/whatsapp.png" alt="WhatsApp" />
          </div>
          <div class="meeting-label-title">Whatsapp</div>
        </div>
      </div>
      <PhoneInput
        :defaultPhoneNumber="defaultPhoneNumber"
        :defaultCountryCode="defaultCountryCode"
        @update:phoneNumber="updatePhoneNumber"
      />
    </div> -->
    <button v-if="!isLoading" @click="sendRequest" class="meeting-request">
      Send to Parent
    </button>
    <button v-else disabled class="meeting-request"><Loading /></button>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import MeetingInput from "../common/MeetingInput.vue";
import PhoneInput from "../common/PhoneInput.vue";
import Loading from "../common/Loading.vue";
import { MeetingDetails } from "../../type";
import { ArrowDownIcon } from "@/assets/images/icons/components";

const defaultPhoneNumber = ref<string>("");
const defaultCountryCode = ref<string>("+1");
const phoneNumber = ref<string>("");
const meetingDetails = ref<MeetingDetails | null>(null);
const isOpenTutorial = ref<boolean>(true);
const isOpenFormat = ref<boolean>(true);

const emits = defineEmits(["send-request"]);

const props = withDefaults(defineProps<{ isLoading: boolean }>(), {
  isLoading: false,
});

const updatePhoneNumber = (newPhoneNumber: string) => {
  phoneNumber.value = newPhoneNumber;
};

const updateMeetingDetails = (details: MeetingDetails) => {
  meetingDetails.value = details;
};

const createMeeting = () => {
  window.open("https://calendar.google.com/calendar/u/0/r/eventedit", "_blank");
};

const toggleTutorial = () => {
  // if (isOpenFormat.value && !isOpenTutorial.value) {
  //   isOpenFormat.value = !isOpenFormat.value;
  // }
  isOpenTutorial.value = !isOpenTutorial.value;
};
const toggleCorretFormat = () => {
  // if (isOpenTutorial.value && !isOpenFormat.value) {
  //   isOpenTutorial.value = !isOpenTutorial.value;
  // }
  isOpenFormat.value = !isOpenFormat.value;
};

const sendRequest = async () => {
  emits("send-request", {
    phone: phoneNumber.value,
    details: meetingDetails.value,
  });
};
</script>

<style lang="scss">
@import "../../styles/index.scss";
.meeting {
  @include flexbox(column, flex-start, flex-start, null, null, 12px);
  width: 100%;
  &-note {
    @include subtitle;
  }
  &-container {
    @include flexbox(column, flex-start, flex-start, null, null, 8px);
    width: 100%;
  }
  &-header {
    @include flexbox(row, space-between, center);
    width: 100%;
  }
  &-content {
    @include flexbox(row, flex-start, flex-start, null, null, 16px);
    width: 100%;
  }
  &-left {
    @include flexbox(column, flex-start, flex-start, null, null, 8px);
    width: 45%;
    height: 100%;
  }
  &-right {
    @include flexbox(column, flex-start, flex-start, null, null, 8px);
  }
  &-label {
    @include flexbox(row, flex-start, center, null, 1, 8px);
    &-logo {
      @include icon(24px, 24px);
      display: flex;
    }
    &-title {
      @include typography(14px, 500, 1.5, normal, $neutral-300);
    }
  }
  &-create {
    @include flexbox(row, center, center, null, null, 8px);
    @include typography(14px, 500, 1.5, normal, $primary-500);
    border: 1px solid #169c8a;
    border-radius: 40px;
    background: transparent;
    padding: 8px 16px;
    &-mb {
      display: none;
    }
  }
  &-preview {
    @include flexbox(column, flex-start, flex-start, null, null, 8px);
    font-size: 14px;
    width: 100%;
    &-title {
      @include typography(14px, 600, 1.5, normal, $primary-500);
      text-transform: uppercase;
    }
    &-items {
      background: #f7f8f7;
      padding: 16px;
      width: 100%;
      border-radius: 12px;
    }
    span {
      font-weight: 600;
    }
  }
  &-request {
    @include typography(16px, 600, 1.5, normal, $neutral-900);
    background: linear-gradient(99.65deg, #24be85 1.57%, #13a7a4 65.99%);
    color: white;
    border: none;
    padding: 12px 32px;
    border-radius: 40px;
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &-steps {
    @include flexbox(column, flex-start, flex-start, null, null, 16px);
    width: 100%;
    &-name {
      @include typography(14px, 500, 1.5, normal, $neutral-300);
      span {
        font-weight: 600;
      }
    }
  }
  &-tutorial {
    width: 100%;
    overflow: hidden;
    padding: 8px;
    border: 1px solid #8cc9ff;
    border-radius: 16px;
    &.tutorial-format {
      border: 1px solid #a7dc9b;
    }
    &-header {
      @include flexbox(row, space-between, center);
      width: 100%;
      cursor: pointer;
      p {
        @include typography(14px, 500, 1.5, normal, $neutral-300);
      }
    }
    &-content {
      width: 100%;
      max-height: 0;
      transition: max-height 0.3s ease-in-out;
      img {
        width: 100%;
        height: auto;
        object-fit: contain;
      }
      &.opened {
        max-height: 200px;
      }
    }
    &-correctformat {
      padding: 8px;
      @include flexbox(column, flex-start, flex-start, null, null, 4px);
      span {
        @include typography(14px, 400, 1.5, normal, $neutral-300);
      }
    }
  }

  @include responsive-for(tablet) {
    &-content {
      @include flexbox(column, flex-start, flex-start, null, null, 16px);
      width: 100%;
    }
    &-left {
      width: 100%;
      height: 100%;
    }
    &-right {
      width: 100%;
    }
    &-create {
      display: none;
      &-mb {
        display: block;
        width: 100%;
      }
    }
  }
}
</style>
