<script lang="ts" setup>
import { computed, defineProps, ref } from "vue";
import CardContainer from "../common/CardContainer.vue";
import { ParentInformation } from "../../type";
import ParentDetails from "./ParentDetails.vue";
import ParentControls from "./ParentControls.vue";
import ParentMeetingInfo from "./ParentMeetingInfo.vue";
import { ArrowDownIcon } from "@/assets/images/icons/components";

interface ParentWithoutChildren extends Omit<ParentInformation, "children"> {}

interface Props {
  data: ParentWithoutChildren;
}

const emits = defineEmits(["reload", "on-unlink"]);
const props = withDefaults(defineProps<Props>(), {
  data: () => ({
    id: 0,
    email: "",
    username: "",
    display_name: "",
    avatar_url: "",
    nationality: "",
    phone: "",
    meeting_information: [],
  }),
});

const isOpenList = ref<boolean>(true);

const meetingList = computed(() => {
  return props.data.meeting_information;
});

const removeMeetingFromList = (id: string | number) => {
  if (meetingList.value) {
    meetingList.value.splice(0, 1);
  }
};

const reloadParentData = () => {
  emits("reload");
};

const handleUnlinkParent = () => {
  emits("on-unlink");
};

const toggleListMeetings = () => {
  isOpenList.value = !isOpenList.value;
};
</script>

<template>
  <CardContainer>
    <div class="parentCard">
      <ParentDetails :data="data" @on-unlink="handleUnlinkParent" />
      <div class="parentCard-divider"></div>
      <ParentControls
        :phone="data.phone || ''"
        :parent-id="data.id"
        @reload="reloadParentData"
      />
      <div class="parentCard-meetings">
        <div class="parentCard-meetings-header" @click="toggleListMeetings">
          <p>Upcoming meeting</p>
          <span :class="isOpenList ? 'arrow-opened' : ''">
            <ArrowDownIcon />
          </span>
        </div>
        <div
          class="parentCard-meetings-list"
          :class="isOpenList ? 'opened' : ''"
        >
          <ParentMeetingInfo
            v-for="(item, idx) in meetingList"
            :key="idx"
            :data="item"
            @delete="removeMeetingFromList(idx)"
          />
        </div>
      </div>
    </div>
  </CardContainer>
</template>

<style lang="scss" scoped>
@import "../../styles/index.scss";
.parentCard {
  @include flexbox(column, flex-start, center, null, null, 16px);
  width: 100%;
  &-divider {
    height: 1px;
    width: 100%;
    background: linear-gradient(
      90deg,
      rgba(224, 226, 225, 0) 0%,
      #e0e2e0 49.52%,
      rgba(224, 226, 224, 0.15625) 99.04%
    );
  }
  &-meetings {
    @include flexbox(column, center, center, null, null, 8px);
    width: 100%;
    &-header {
      @include flexbox(row, space-between, center);
      width: 100%;
      cursor: pointer;
      p {
        color: #f89900;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 600;
      }
      span {
        transform: rotate(0deg);
        transition: transform 0.3s ease-in-out;
      }
      span.arrow-opened {
        transform: rotate(180deg);
      }
    }
    &-list {
      @include flexbox(column, flex-start, center, null, null, 8px);
      width: 100%;
      overflow: hidden;
      max-height: 0;
      transition: max-height 0.3s ease-in-out;
      &.opened {
        max-height: 600px;
      }
    }
  }
}
</style>
