import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d5fd461e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "d-flex flex-column",
  style: {"gap":"16px"}
}
const _hoisted_2 = { class: "overView d-flex justify-content-between" }
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  class: "d-flex flex-column justify-content-between",
  style: {"gap":"4px"}
}
const _hoisted_5 = { class: "text-title" }
const _hoisted_6 = { class: "text-description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.overViews, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "overView-items",
          key: index,
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleDetailsOverView && _ctx.handleDetailsOverView(...args)))
        }, [
          _createElementVNode("img", {
            src: require(`@/assets/images/logos/png/${item?.image}.png`),
            style: {"width":"80px","height":"80px","object-fit":"contain"}
          }, null, 8, _hoisted_3),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("p", _hoisted_5, _toDisplayString(item?.title), 1),
            _createElementVNode("p", _hoisted_6, _toDisplayString(item?.quantity), 1)
          ])
        ]))
      }), 128))
    ])
  ]))
}